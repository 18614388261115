import React, {FC} from 'react';
import {Box, Container, Typography} from "@mui/material";

const SectionBase: FC<{ children: any, title?: string, bgColor?: string, ancre: string, colorReverse?: boolean, colorTitle?:string}>
    = ({children, title, bgColor, ancre, colorReverse, colorTitle}) => {
    return (
            <Box id={ancre} minHeight={"50vh"} justifyContent={"center"} alignContent={"center"} bgcolor={bgColor?bgColor: "white"}>
        <Container>
                {title &&
                    <Typography variant="h1" color={colorReverse?"white": colorTitle}>{title}</Typography>
                }
                {children}
        </Container>
            </Box>
    );
};

export default SectionBase;
