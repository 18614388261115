import React, {FC} from 'react';
import logo from "../../logo.svg";
import {Box, Typography} from "@mui/material";

const Footer:FC<{}> = ({}) => {
    return (
        <footer>
            <Box height={50} alignContent="center" bgcolor={"#000"}>
                <Typography variant="body2" align="center" color="white">Tous droits réservés - ©2024 - French PAC</Typography>
            </Box>
        </footer>
    );
};

export default Footer;
