import React, {FC} from 'react';
import SectionBase from "../../common/SectionBase";
import {Stack, Typography} from "@mui/material";
import Ardoise from "./Ardoise";

const Description: FC<{ description: any }> = ({description}) => {
    return (
        <SectionBase bgColor={"#fff"} ancre="description">
            <Typography variant="h1" align="center" mb={5}>{description.content1}</Typography>
            <Stack direction={"row"} gap={15}>
                <Stack gap={5} alignContent={"center"} justifyContent={"center"}>
                    <Typography variant="h2" align="center" whiteSpace="pre-wrap">{description.content2}</Typography>
                </Stack>
                <Ardoise description={description}/>
            </Stack>
        </SectionBase>
    );
};

export default Description;
