import React, {FC} from 'react';
import {Box} from "@mui/material";

const GradientDivider: FC<{ colorTop: string, colorBottom: string }> = ({colorTop, colorBottom}) => {
    return (
        <Box sx={{background: "linear-gradient(180deg," + colorTop + " 0%, " + colorBottom + " 100%)"}} height="1vh">
        </Box>
    );
};

export default GradientDivider;
