import {useMemo} from "react";
import {CssBaseline, responsiveFontSizes, StyledEngineProvider} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import shadows from "./shadows";

// ----------------------------------------------------------------------

export default function ThemeConfig({children}: any) {

    const theme: any = useMemo(
        () =>
            responsiveFontSizes(createTheme({
                palette: {...palette},
                shape: shape,
                typography: typography,
                breakpoints,
                shadows: shadows,
                })),
        [],
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}