import {FC} from 'react';
import Navbar from "./navbar/Navbar";
import Hero from "./hero/Hero";

const Header:FC<{}> = ({}) => {
    return (
       <>
           <Navbar/>
           <Hero/>
       </>
    );
};

export default Header;
