import React, {FC} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import SectionBase from "../../common/SectionBase";

const Offer: FC<{ offers: any[] }> = ({offers}) => {
    return (
        <SectionBase title="Offres" bgColor={"#fff"} ancre="offer" colorTitle={"#203053"}>
            <Grid container>
                {offers.map((offer, index) => (
                    <Grid key={index} item xs={12} md={3} px={2} mt={10} >
                        <Box height={200} sx={{
                            alignContent:"center",
                            borderRadius: 5,
                            mb:5,
                            borderTop: "10px solid" + offer.borderTop,
                            borderLeft: "10px solid" + offer.borderLeft,
                            borderRight: "10px solid" + offer.borderRight,
                            borderBottom: "10px solid" + offer.borderBottom,
                            color:"#203053",
                            ":hover":{
                                backgroundColor: "#203053",
                                cursor: "pointer",
                                color: "white",
                            }
                        }}>
                           <Typography variant="h2" textAlign={"center"} lineHeight={1} whiteSpace="pre-wrap">
                            {offer.title}
                           </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </SectionBase>
    );
};

export default Offer;
