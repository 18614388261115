import {FC, useState} from 'react';
import {AppBar, Box, Container, Link, Stack, Typography} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import data from "../../../data/data.json"
import Logo from "../../common/Logo";

const Navbar: FC<{}> = ({}) => {
        const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

        // @ts-ignore
    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
            setAnchorElNav(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
            setAnchorElNav(null);
        };

        return (
            <AppBar position="static" color="inherit">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Logo width={20}/>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                mt:1,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'SNPro',
                                fontWeight: 700,
                                letterSpacing: '.2rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                            dangerouslySetInnerHTML={{__html: data.app.title}}
                        ></Typography>

                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {data.menu.map((item, index) => (
                                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                                        <Link href={"#" + item.key} variant="h5"><Typography
                                            dangerouslySetInnerHTML={{__html: item.name}}></Typography></Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <Logo width={20}/>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'Sofadi One',
                                fontWeight: 700,
                                letterSpacing: '.2rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            {data.app.title}
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            <Stack direction="row" justifyContent="center" gap={3} sx={style.navbar}>
                            {data.menu.map((item, index) => (
                                    <Link key={index} href={"#" + item.key} variant="h5"
                                          onClick={handleCloseNavMenu} sx={style.link}><Typography
                                        dangerouslySetInnerHTML={{__html: item.name}}></Typography></Link>
                            ))}
                            </Stack>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    /*return (
        <Stack id="top" direction="row" bgcolor={"#ddccaa"} sx={style.navbar} justifyContent="center">
            <Box sx={style.logoBox}>
            <Box  sx={style.logo}>
                <Typography variant="body2" align="center">LOGO</Typography>
            </Box>
            </Box>
        <Stack direction="row" justifyContent="center" gap={3} sx={style.navbar}>
            {data.menu.map((item, index) => (
                <Link key={index} href={"#"+item.key} variant="h5" sx={style.link}>{item.name}</Link>
            ))}
        </Stack>
        </Stack>
    );*/
};

export default Navbar;

const style: any = {
    link:{
        textDecoration: "none",
        color:"black"
    },
    navbar:{
        p:2.5
    },
    logoBox:  {
        width:0,
    },
}