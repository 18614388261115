import React from 'react';
import './App.css';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Contact from "./components/body/contact/Contact";
import Offer from "./components/body/offer/Offer";
import Testimonial from "./components/body/testimonial/Testimonial";
import data from "./data/data.json"
import Section from './components/common/Section';
import Description from "./components/body/description/Description";
import PictureGallery from "./components/body/pictureGallery/PictureGallery";
import {Container} from "@mui/material";
import Features from "./components/body/features/Features";

function App() {
    return (
        <>
            <Header/>
                <Description description={data.description}/>
                <Features/>
                <Section item={data.features[0]} reverse colorTitle={"#81EB5C"}/>
                 <Section item={data.features[1]} reverse colorTitle={"#EBE55C"}/>
                <Section item={data.features[2]} reverse colorTitle={"#5CD1EB"}/>
                <Offer offers={data.offers}/>
                <Section bgColor={"#fff"} colorTitle={"#203053"} item={data.trainer}/>
                <PictureGallery/>
                <Section bgColor={"#203053"} item={data.about} reverse colorReverse />
                <Testimonial testimonials={data.testimonials}/>
                <Contact/>
            <Footer/>
        </>
    );
}

export default App;
