import {Box, SxProps, Typography} from "@mui/material";
import {FC} from "react";

const Ardoise:FC<{description: any}> = ({description}) => {
    return (
        <Box sx={style}>
            {description.ardoise.map((line: string, index: number) =>
            <Typography key={index} variant="body1" align="justify" whiteSpace="pre-wrap">{line + "\n "}</Typography>
            )}
        </Box>
    );
};

export default Ardoise;

const style: SxProps = {
    color: "white",
    paddingX: 2.55,
    '@media (min-width:600px)': {
        height: 200,
        width: 300,
    },
    '@media (min-width:900px)': {
        height: 350,
        width: 500,
    },
    height: 100,
    width: 100,
    borderRadius: "2.5%",
    alignContent: "center",
    bgcolor:"#24262b",
    border: "20px solid #F09038",
    '& p:first-of-type': {
        textAlign: "center",
    },
}
