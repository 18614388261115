import React, {FC} from 'react';
import SectionBase from "../../common/SectionBase";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import data from "../../../data/data.json"

const Features: FC<{ colorReverse?: boolean }> = ({colorReverse}) => {
        return (
            <SectionBase bgColor={"white"} ancre={"feature"}>
                <Grid container height={"70vh"}>
                    {data.features.map((feature, index) =>
                        <Grid key={index} item md={4} xs={12} px={2}>
                            <Box height={"25vh"} width={"100%"} sx={{
                                backgroundImage: (feature.picture !== "") ? (`url(${require("../../../" + feature.picture)})`) : 'none',
                                backgroundColor: "grey",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                borderTopLeftRadius: 45,
                                borderTopRightRadius: 45
                            }}>
                            </Box>
                            <Stack justifyContent={"space-between"} height="60%"
                                   color={colorReverse ? "white" : "black"}>
                                <Box>
                                    <Typography variant="h1" color={feature.bgColor}>{feature.title}</Typography>
                                    <Typography variant="body1" align="justify"
                                                whiteSpace="pre-wrap">{feature.content}</Typography>
                                </Box>
                                <Button variant="contained" size="large" sx={{backgroundColor: feature.bgColor}}>{feature.buttonText}</Button>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </SectionBase>
        )
            ;
    }
;

export default Features;
