import React, {FC, useState} from 'react';
import {Box, Link, SxProps, Typography} from "@mui/material";

const Description: FC<{ item: any }> = ({item}) => {
    const [hover, setHover] = useState<boolean>(false)
    return (
        <Link href={"#" +item.key} underline="none">
        <Box sx={style} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} bgcolor={item.color}>
            {!hover ?
                <Typography variant="h2" align="center">{item.letter}</Typography>
                :
                <Typography variant="h3" align="center">{item.word}</Typography>
            }
        </Box>
        </Link>
    );
};

export default Description;

const style: SxProps = {
    color: "white",
    '@media (min-width:600px)': {
        height: 200,
        width: 200,
    },
    '@media (min-width:900px)': {
        height: 300,
        width: 300,
    },
    height: 100,
    width: 100,
    borderRadius: "50%",
    alignContent: "center",
    cursor: "pointer"
}