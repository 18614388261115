import {alpha} from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: any, color2: any) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

function createLinearGradient(color1: any, color2: any) {
    return `linear-gradient(to right, ${color1}, ${color2})`;
}

function createDiagonalGradient(color1: any, color2: any) {
    return `linear-gradient(125deg, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
    0: "#FFFFFF",
    100: "#F9FAFB",
    200: "#F4F6F8",
    300: "#DFE3E8",
    400: "#C4CDD5",
    500: "#919EAB",
    600: "#637381",
    700: "#454F5B",
    800: "#212B36",
    900: "#161C24",
    500_8: alpha("#919EAB", 0.08),
    500_12: alpha("#919EAB", 0.12),
    500_16: alpha("#919EAB", 0.16),
    500_24: alpha("#919EAB", 0.24),
    500_32: alpha("#919EAB", 0.32),
    500_48: alpha("#919EAB", 0.48),
    500_56: alpha("#919EAB", 0.56),
    500_80: alpha("#919EAB", 0.8),
};


const GRADIENTS = {
    b5: createGradient("#39c4ff", "#7081ff"),
    cf: createGradient("#feb2a3", "#fd7c9e"),
    inc: createGradient("#9cddff", "#e89ffd"),
    skills: createGradient("#fbda7e", "#ffa934"),
};

const CONTEXT_COLORS = {
    advisor: {background: "#001338", font: "#FFF"},
    collab: {background: "#f6f9fd", font: "#2E3A59"},
    admin: {background: "#001338", font: "#FFF"},
    recruiter: {background: "#0b0b0b", font: "#FFF"},
};

const palette = {
    primary: {
        main: '#D70DDB',
        mainTransparency: {
            10: alpha("#9d62de", 0.1),
            20: alpha("#9d62de", 0.2),
            30: alpha("#9d62de", 0.3),
            40: alpha("#9d62de", 0.4),
            50: alpha("#9d62de", 0.5),
            60: alpha("#9d62de", 0.6),
            70: alpha("#9d62de", 0.7),
            80: alpha("#9d62de", 0.8),
            90: alpha("#9d62de", 0.9),
        }
    },
    secondary: {
        main: '#57decd',
        mainTransparency: {
            10: alpha("#57decd", 0.1),
            20: alpha("#57decd", 0.2),
            30: alpha("#57decd", 0.3),
            40: alpha("#57decd", 0.4),
            50: alpha("#57decd", 0.5),
            60: alpha("#57decd", 0.6),
            70: alpha("#57decd", 0.7),
            80: alpha("#57decd", 0.8),
            90: alpha("#57decd", 0.9),
        }
    },
    error: {
        main: '#f85b55',
    },
    warning: {
        main: '#f8c248',
    },
    info: {
        main: '#55cef8',
    },
    success: {
        main: '#90f848',
    },
    button: {
        main: '#ffffff'
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        button: true;
    }
}

declare module '@mui/material/styles' {
    interface PaletteColor {
        mainTransparency?: any;
    }

    interface SimplePaletteColorOptions {
        mainTransparency?: any;
    }

}
export default palette;

