import React, {FC} from 'react';
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import SectionBase from "./SectionBase";

const Section: FC<{ bgColor?: string, item: any, reverse?: boolean, colorReverse?: boolean, colorTitle?: string}>
    = ({bgColor, item, reverse, colorReverse, colorTitle}) => {
    return (
        <SectionBase bgColor={bgColor} colorTitle={colorTitle} ancre={item.id} colorReverse={colorReverse}>
            <Grid container direction={reverse ? "row" : "row-reverse"} justifyContent="space-evenly">
                <Grid item xs={12} md={5}>
                    <Box height={"35vh"} width={"100%"} sx={{
                        backgroundImage: (item.picture !== "") ? (`url(${require("../../" + item.picture)})`) : 'none',
                        backgroundColor: "grey",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderTopRightRadius:45,
                        borderBottomRightRadius:45
                    }}>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack justifyContent={"space-around"} height="100%" color={colorReverse ? "white" : "black"}>
                    {item.title &&
                        <Typography variant="h1" color={colorTitle}>{item.title}</Typography>
                    }
                        <Typography variant="body1" align="justify" whiteSpace="pre-wrap">{item.content}</Typography>
                        <Button variant="contained" size="large" sx={{backgroundColor: colorTitle}}>{item.buttonText}</Button>
                    </Stack>
                </Grid>
            </Grid>
        </SectionBase>
    );
};

export default Section;
