import logo from "../../logo.svg";
import {FC} from "react";
import {Box} from "@mui/material";

const Logo:FC<{width:number}> = ({width}) => {
    return (
        <Box sx={{display: {xs: 'none', md: 'flex'}, m: 1}} alignContent="center">
            <img src={logo} alt="logo" width={width}/>
        </Box>
    );
};

export default Logo;
