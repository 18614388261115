import {FC} from 'react';
import {Box, Button, Grid, Stack, TextField} from "@mui/material";
import SectionBase from "../../common/SectionBase";
import MenuItem from "@mui/material/MenuItem";

const offer = [
    {
        value: 'Select offer',
        label: '',
    },
    {
        value: 'BASIC',
        label: 'BASIC',
    },
    {
        value: 'DUO',
        label: 'DUO',
    },
    {
        value: 'DUO',
        label: 'DUO',
    },
    {
        value: 'P.A.C',
        label: 'P.A.C',
    }
];
const Contact: FC<{}> = ({}) => {
    return (
        <SectionBase bgColor={"#D72426"} ancre="contact" title="Contact">
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '38ch'},
                }}
                noValidate
                autoComplete="off"
            >
                <Grid container direction="row" gap={2} justifyContent="center">
                    <Grid item xs={12} md={5}>
                        <TextField id="outlined-basic" label="Prénom" variant="outlined"/>
                        <TextField id="outlined-basic" label="Nom" variant="outlined"/>
                        <TextField id="outlined-basic" label="E-mail" variant="outlined"/>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Offer"
                            defaultValue="Select offer"
                            helperText="Please select your offer"
                        >
                            {offer.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={5} mb={10}>
                        <TextField id="outlined-basic" label="Message" variant="outlined" multiline rows={10}/>
                    </Grid>
                </Grid>
                <Stack alignItems="center">
                        <Button size="large" sx={{px:20}} variant="contained">Envoyer</Button>
                </Stack>
            </Box>
        </SectionBase>
    );
};

export default Contact;

